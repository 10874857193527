<template>
	<div class="calendar" @click="closeCalendar">
		<div class="close">✕</div>
		<div class="meetings-iframe-container"
			data-src="https://marketing.amardesign.co.uk/meetings/amardesign/new-business?embed=true">
		</div>
	</div>
</template>

<script setup>

const store = useGlobalStore()

const closeCalendar = () => {
	store.closeCalendar()
}

useHead({
	script: [
		{ type: 'text/javascript', src: 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js', body: true }
	]
})

</script>

<style lang="scss" scoped>
.calendar {
	position: fixed;
	inset: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, .75);
	z-index: 1000000;
}

.close {
	position: fixed;
	top: 60px;
	right: 60px;
	font-size: 30px;
	color: var(--color-white);
	cursor: pointer;

	&:hover {
		color: var(--color-primary);
	}
}

.meetings-iframe-container {
	position: absolute;
	width: 900px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
</style>